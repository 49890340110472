<template>
    <div>
		<CustomTable
			id_table="plan_cuve"
			ref="table"
			:items="localisations"
			:busy.sync="table_busy"
			primaryKey="semencelocalisation_id"
    		:externSlotColumns="extern_slot_columns"
		>
			<template v-slot:custom-slot-cell(stallion)="{ data }">
				<span v-for="(stallion, idx) in data.stallion" class="d-flex align-items-center" :key="data.semencelocalisation_id+'_'+idx+'_'+stallion.horse.horse_id">
					<router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: stallion.horse.horse_id }}">
						{{ stallion.label }} {{ stallion.couleur }}
					</router-link>
					<font-awesome-icon class="previewColIdentifier" @click.stop="showDetails(stallion.horse)" :icon="['fas', 'eye']" />
				</span>
			</template>
		</CustomTable>
    </div>
</template>

<script>
import { EventBus } from 'EventBus'
import StockSemence from '@/mixins/StockSemence'
import Navigation from "@/mixins/Navigation.js"
import Shutter from "@/mixins/Shutter"
import ShutterSemence from "@/mixins/shutters-manager/Semence"

export default {
	name: 'ListePlanCuve',
	mixins: [StockSemence, Navigation, Shutter, ShutterSemence],
	data() { 
		return {
			table_busy: true,
	        localisations: [],
	        extern_slot_columns: ['stallion'],
			events_tab: {
                'TableAction::goToExportGlobalCuve': this.exportGlobalCuve,
            }
	    }
	},
	created() {
		this.reloadLocSemence()
	},
	methods: {
		openModalLocalisation() {
			this.$refs.modalLocalisation.openModal()
		},

		async reloadLocSemence() {
			this.table_busy = true

			const localisations = await this.getLocalisationSemence()

			const loc_ids = localisations.map(loc => loc.semencelocalisation_id)
			const stocks = await this.getStockStallionByLocalisation(loc_ids)

			localisations.forEach(loc => {
				let stallions = stocks[loc.semencelocalisation_id]
				if(stallions) {
					stallions = stallions.map(stallion => {
						return {
							horse: stallion.horse,
							label: stallion.horse.horse_nom + " (" + stallion.semenceemplacement_dispo + " " + this.getTrad("monte.paillettes") + ")",
							couleur: stallion.couleur ? '(' +this.$t('monte.couleur.' + stallion.couleur.semencecouleur_code) + ')' : ''
						}
					})
				}

				loc.stallion = stallions
			})

			this.localisations = localisations

			this.table_busy = false
		},

		showDetails(horse) {
			this.setupStallionDetails(horse, null, false)
		},

		async exportGlobalCuve(){
			await this.exportAllStockLocal();
			EventBus.$emit("TableAction::stopSpin")
		}
	},
	components: {
		CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
	}
};
</script>
